import React from 'react'
import { Button } from '../../components/button'
import { LIST_IMAGES } from '../../env'
import { Randomizer } from '../../helpers/randomizer'
import { requestTraffics } from '../../request/hooks/api'
import './style.scss'

const Block = ({
  Svg,
  title,
  desc,
}: {
  Svg: any
  title: string
  desc: string
}) => {
  return (
    <div className={'pad__advantage__block'}>
      <div className={'pad__advantage__block__img'}>
        <Svg />
      </div>
      <div className={'pad__advantage__block__text'}>
        <div className={'pad__advantage__block__text__title'}>{title}</div>
        <div className={'pad__advantage__block__text__desc'}>{desc}</div>
      </div>
    </div>
  )
}

function CheckTraffics(link: string | null) {
  if (link) {
    let params = new URL(link).searchParams
    requestTraffics(params.get('link'), params.get('operationHash'))
      .then(link => {
        //@ts-ignore
        window.location.href = link.data.link
      })
      .catch(() => {
        //@ts-ignore
        window.location.href = localStorage.getItem('offer_link')
      })
  } else if (localStorage.getItem('offer_link')) {
    //@ts-ignore
    window.location.href = localStorage.getItem('offer_link')
  }
}

const Traffics = (props: { link?: string }) => {
  React.useEffect(() => {}, [])
  React.useEffect(() => {
    document.getElementById('tg_icon')?.remove()
  })

  return (
    <div className={'pad'}>
      <div
        className={'pad__container'}
        style={{
          backgroundImage: `url(${LIST_IMAGES[Randomizer(0, 5)]})`,
        }}
      >
        <span className={'pad__text-title'}>
          Займ одобрен и готов к переводу! <br />
          Выберете нужную сумму! <br />
          Подтвердите анкету! <br />
          10 минут и деньги на карте!
        </span>
        <Button
          theme={'dark'}
          title={'Получить займ'}
          onClick={() => CheckTraffics(props.link || null)}
        />
      </div>
      <div id={'doc-2'} style={{ marginBottom: 20, marginTop: 20 }}>
        <ins
          className="dd15595d"
          data-key="eb00348fe086d276da7d6cd2302d06c2"
        ></ins>
      </div>
      <div className={'pad__advantage'}>
        <Block
          Svg={() => (
            <svg
              width="47"
              height="47"
              viewBox="0 0 47 47"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g>
                <path d="M47 27.906a4.351 4.351 0 00-1.275-3.082c.915-1.001 1.38-2.343 1.253-3.739-.229-2.489-2.471-4.44-5.107-4.44H29.774c.6-1.819 1.56-5.154 1.56-7.833 0-4.247-3.61-7.833-5.876-7.833-2.032 0-3.487 1.146-3.548 1.193a.98.98 0 00-.368.765v6.641L15.9 21.796l-.233.12v-.375a.98.98 0 00-.98-.979H4.896A4.9 4.9 0 000 25.458v15.667a4.9 4.9 0 004.896 4.896h5.875a4.907 4.907 0 004.608-3.238c1.63.839 3.822 1.28 5.184 1.28h17.975c2.133 0 3.999-1.438 4.438-3.42a4.42 4.42 0 00-.353-2.943 4.388 4.388 0 001.96-5.875A4.388 4.388 0 0047 27.906zm-4.13 2.417a.984.984 0 00-.819.663c-.12.363-.02.76.259 1.023.497.468.773 1.098.773 1.772a2.44 2.44 0 01-2.17 2.417.984.984 0 00-.818.663c-.12.363-.02.76.258 1.023.645.607.903 1.459.71 2.336-.241 1.093-1.303 1.884-2.525 1.884H20.563c-1.59 0-4.258-.746-5.182-1.672a.98.98 0 00-1.673.693 2.941 2.941 0 01-2.937 2.937H4.896a2.941 2.941 0 01-2.938-2.937V25.458a2.941 2.941 0 012.938-2.937h8.812v.979c0 .339.177.654.466.834a1 1 0 00.952.041l1.958-.979a.978.978 0 00.45-.464l5.876-12.73c.059-.129.09-.27.09-.41V3.467c.407-.225 1.114-.53 1.958-.53 1.074 0 3.917 2.667 3.917 5.874 0 3.447-1.876 8.415-1.894 8.464a.986.986 0 00.108.905.985.985 0 00.807.423H41.87c1.633 0 3.02 1.17 3.157 2.661a2.926 2.926 0 01-1.394 2.771.983.983 0 00.067 1.706 2.424 2.424 0 011.34 2.164 2.44 2.44 0 01-2.171 2.417z"></path>
                <path d="M14.688 22.52a.98.98 0 00-.98.98v17.625a.98.98 0 001.959 0V23.5a.98.98 0 00-.98-.98z"></path>
              </g>
            </svg>
          )}
          title={'Гибкие условия'}
          desc={
            'Вы сами выбераете наиболее подходящие для Вас условия (сумма ипериод займа)'
          }
        />
        <Block
          Svg={() => (
            <svg
              width="25"
              height="47"
              viewBox="0 0 25 47"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g>
                <path d="M21.313.734H3.688A3.676 3.676 0 00.015 4.406v38.188a3.676 3.676 0 003.671 3.672h17.625a3.676 3.676 0 003.672-3.672V4.406A3.676 3.676 0 0021.312.734zm-3.834 1.47l-.44 2.202H7.96l-.44-2.203h9.958zm6.037 40.39a2.206 2.206 0 01-2.203 2.203H3.688a2.206 2.206 0 01-2.204-2.203V4.406a2.206 2.206 0 012.204-2.203h2.335l.616 3.082a.734.734 0 00.72.59h10.282a.734.734 0 00.72-.59l.616-3.082h2.335a2.206 2.206 0 012.204 2.203v38.188z"></path>
                <path d="M8.094 41.86H3.688a.734.734 0 100 1.468h4.406a.734.734 0 100-1.469zM11.031 41.86h-.734a.734.734 0 100 1.468h.734a.734.734 0 100-1.469z"></path>
              </g>
            </svg>
          )}
          title={'Онлайн оформление'}
          desc={'С любого мобильного устройства или компьютера по всей россии'}
        />
        <Block
          Svg={() => (
            <svg
              width="47"
              height="45"
              viewBox="0 0 47 45"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g>
                <path d="M43.39 10.899L15.417.715a5.496 5.496 0 00-7.027 3.28L3.35 17.84A5.491 5.491 0 000 22.891v16.45a5.49 5.49 0 005.483 5.484H35.25a5.49 5.49 0 005.483-5.484v-5.279c.118-.22.22-.447.306-.68l5.6-15.368.032-.087a5.494 5.494 0 00-3.28-7.028zm.198 10.902L40.21 20.57a5.483 5.483 0 00-4.961-3.162h-3.733L8.257 8.942l1.268-3.485.07-.19 35.335 12.86-1.342 3.674zM7.72 10.414l19.217 6.994H5.483c-.106 0-.21.01-.313.016l2.55-7.01zM39.167 39.34a3.921 3.921 0 01-3.917 3.917H5.483a3.921 3.921 0 01-3.916-3.917v-16.45a3.921 3.921 0 013.916-3.916H35.25a3.921 3.921 0 013.917 3.916v16.45zm1.566-9.697v-6.753c0-.156-.01-.313-.024-.47l2.345.856-2.32 6.367zm4.658-13.02L10.205 3.824a3.931 3.931 0 014.678-1.636L42.856 12.37a3.926 3.926 0 012.535 4.255z"></path>
                <path d="M10.967 29.158a2.35 2.35 0 002.35-2.35v-3.133a2.35 2.35 0 00-2.35-2.35h-4.7a2.35 2.35 0 00-2.35 2.35v3.133a2.35 2.35 0 002.35 2.35h4.7zm0-1.566h-2.35v-1.567h3.133v.783c0 .433-.35.784-.783.784zm.783-3.917v.783H8.617v-1.566h2.35c.432 0 .783.35.783.783zm-5.483-.783h.783v1.566H5.483v-.783c0-.433.351-.783.784-.783zm-.784 3.916v-.783H7.05v1.567h-.783a.783.783 0 01-.784-.784zM6.267 33.858a.783.783 0 100 1.567h4.7a.783.783 0 000-1.567h-4.7zM18.8 33.858h-4.7a.783.783 0 000 1.567h4.7a.783.783 0 100-1.567zM26.633 33.858h-4.7a.783.783 0 000 1.567h4.7a.783.783 0 100-1.567zM34.467 33.858h-4.7a.783.783 0 000 1.567h4.7a.783.783 0 000-1.567zM7.833 38.558H6.267a.783.783 0 100 1.567h1.566a.783.783 0 100-1.567zM13.317 38.558H11.75a.783.783 0 000 1.567h1.567a.783.783 0 000-1.567zM33.683 22.108a3.114 3.114 0 00-2.35 1.082 3.133 3.133 0 100 4.102 3.127 3.127 0 102.35-5.184zm-4.7 4.7a1.567 1.567 0 110-3.134 1.567 1.567 0 010 3.134zm4.7 0a1.567 1.567 0 110-3.134 1.567 1.567 0 010 3.134z"></path>
              </g>
            </svg>
          )}
          title={'Быстрый перевод денег'}
          desc={
            'Деньги поступят сразу после одобрения, на карту или другим удобным для Вас способом'
          }
        />
        <Block
          Svg={() => (
            <svg
              width="47"
              height="47"
              viewBox="0 0 47 47"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g>
                <path d="M46.217 42.3h-2.35V27.417a.783.783 0 00-1.425-.449L31.475 42.635a.783.783 0 00.642 1.232H42.3v2.35a.783.783 0 001.567 0v-2.35h2.35a.783.783 0 100-1.567zm-3.917 0h-8.68l8.68-12.398V42.3z"></path>
                <path d="M32.117 45.433h-9.274l8.622-10.153a.861.861 0 00.055-.072 5.449 5.449 0 00-1.23-7.398 6.267 6.267 0 00-9.923 5.09.783.783 0 001.566 0 4.7 4.7 0 017.442-3.818 3.867 3.867 0 01.862 5.22L20.553 45.71A.783.783 0 0021.15 47h10.967a.783.783 0 000-1.567z"></path>
                <path d="M21.167 41.532a.783.783 0 00-.753-.813c-10.22-.38-18.422-8.567-18.82-18.786H4.7a.783.783 0 000-1.566H1.594c.392-10.201 8.572-18.38 18.773-18.772v2.322a.783.783 0 001.566 0V1.595c10.062.389 18.182 8.356 18.763 18.408l-3.055-3.055a.783.783 0 00-1.108 1.108l4.373 4.372a.77.77 0 00.308.225c.03.01.062.017.093.023.054.02.11.033.167.04h.044a.755.755 0 00.176-.035.742.742 0 00.095-.018.772.772 0 00.283-.177l4.432-4.43a.783.783 0 00-1.107-1.108l-3.131 3.129C41.706 8.849 32.448.027 21.206.01c-.02 0-.036-.011-.056-.011-.02 0-.036.01-.056.011C9.457.027.027 9.457.011 21.094c0 .02-.011.036-.011.056 0 .02.01.036.011.056.02 11.35 9.002 20.657 20.345 21.08h.029c.42-.001.766-.334.782-.755z"></path>
                <path d="M31.674 10.626a.783.783 0 00-1.108 0l-8.417 8.417a2.454 2.454 0 00-1.998 0l-3.986-3.986a.784.784 0 00-1.108 1.108l3.978 3.978a2.346 2.346 0 003.776 2.67c.707-.702.89-1.775.453-2.67l8.41-8.41a.783.783 0 000-1.107zm-9.97 11.078a.802.802 0 01-1.108 0 .783.783 0 111.108 0z"></path>
              </g>
            </svg>
          )}
          title={'Работаем круглосуточно'}
          desc={'Без обеда, выходных и праздничных дней'}
        />
      </div>
      <div id={'doc-2'} style={{ marginBottom: 20, marginTop: 20 }}>
        <ins
          className="dd15595d"
          data-key="eb00348fe086d276da7d6cd2302d06c2"
        ></ins>
      </div>
    </div>
  )
}

export default React.memo(Traffics)
