export default {
  suggestionsUrlDadata: `https://suggestions.dadata.ru/suggestions/api/4_1/rs/`,
  tokenDadata: `Token e748ed2c46b932e5f0e504861a6db5d6f9fb65b5`,
  secretKey: `7afda769c3c57548b224c8c523baf1c41ce0269e`,
  urlIP: `https://api.ipify.org?format=json`,
  baseUrl: `https://moneytka.ru`,
}

const DOBCASH = 0 //3002 #a93226
const MONEYTKA = 1 //
const MFORU = 2 //3000
const SMSFIN = 3 //3001
const VASHZAEM = 4 //3003

export const ACTIVE_SITE = DOBCASH

export const NAME_SITE = 'dobcash'

export const LIST_IMAGES = [
  '/assets/pads/1.jpeg',
  '/assets/pads/2.jpeg',
  '/assets/pads/3.jpeg',
  '/assets/pads/4.jpeg',
  '/assets/pads/5.webp',
  '/assets/pads/6.jpeg',
]
