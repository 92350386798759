import {TextField } from '@mui/material'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { schemaStepPersonalData } from './schema'
import { useRequestDaData } from '../../../request/hooks/use-request-dadata'
import { Button } from '../../../components/button'
import SelectDay from '../../../components/SelectDay'

const StepPersonalDate = (props: any) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaStepPersonalData),
    defaultValues: {
      firstName: (props.state.firstName as string) || '',
      lastName: (props.state.lastName as string) || '',
      middleName: (props.state.middleName as string) || '',
      birthday: props.state.birthday || '',
      phone:'',
      email:''
    },
  })

  const optionsDate = [
    2004, 2003, 2001, 2000, 1999, 1998, 1997, 1996, 1995, 1994, 1993, 1992,
    1991, 1990, 1989, 1988, 1987, 1986, 1985, 1984, 1983, 1982, 1981, 1980,
    1979, 1978, 1977, 1976, 1975, 1974, 1973, 1972, 1971, 1970, 1969, 1968,
    1967, 1966, 1965, 1964, 1963, 1962, 1961, 1960, 1959, 1958, 1957, 1956,
    1955, 1954, 1953, 1952,
  ]

  const { search, data: listFirstName }: { data: any; search: any } =
    useRequestDaData({
      url: 'suggest/fio',
      method: 'post',
      params: { query: '', parts: ['NAME'] },
    })

  const onSubmit = (data: any) => {
    // props.setState((pre: any) => ({ ...pre, ...data }))
    // props.setActiveStep((pre: number) => pre + 1)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={'personal-data'}>
      <div className={'personal-data__block-title'}>
        <div className={'personal-data__block-title__title'}>
          Персональные данные
        </div>
        <div className={'personal-data__block-title__desc'}>
          Заполните данные и получите +25% к одобрению
        </div>
      </div>
      <Controller
        name="lastName"
        control={control}
        render={({ field }) => (
          <div className={'personal-data__block-input custom-block'}>
          <label className={'personal-data__label'}>Ваша фамилия</label>
            <TextField
                {...field}
                disabled={true}
                id="formatted-numberformat-input"
                placeholder={'Иванов'}         
            />
        </div>
        )}
      />
      <Controller
        name="firstName"
        control={control}
        render={({ field }) => (
          <div className={'personal-data__block-input custom-block'}>
          <label className={'personal-data__label'}>Ваша имя</label>
            <TextField
                {...field}
                disabled={true}
                id="formatted-numberformat-input"
                placeholder={'Иванов'}         
            />
        </div>
        )}
      />
      <Controller
        name="middleName"
        control={control}
        render={({ field }) => (
          <div className={'personal-data__block-input custom-block'}>
          <label className={'personal-data__label'}>Ваша отчество</label>
            <TextField
                {...field}
                disabled={true}
                id="formatted-numberformat-input"
                placeholder={'Иванов'}         
            />
        </div>
           
        )}
        
      />
      <Controller
        name="birthday"
        control={control}
        render={({ field }) => (
          <SelectDay
            {...field}
            options={optionsDate}
            errorText={errors[field.name]?.message || false}
          />
        )}
      />
<Controller
        name="phone"
        control={control}
        render={({ field }) => (
          <div className={'personal-data__block-input custom-block'}>
            <label className={'personal-data__label'}>Ваш телефон</label>
            <TextField
              {...field}
              disabled={true}
              id="formatted-numberformat-input"
              placeholder={'7(999)-999-99-99'}
             />
          </div>
        )}
      />
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <div className={'personal-data__block-input custom-block'}>
            <label className={'personal-data__label'}>Ваша почта</label>
            <TextField
              {...field}
              type={'email'}
              placeholder={'mail@mail.ru'}
              error={!!errors[field.name]?.message}
              helperText={errors[field.name]?.message}
            />
          </div>
        )}
      />
      <div className={'personal-data__button-block'} style={{ marginTop: 0 }}>
        <Button
          title={'Регистрация'}
          onClick={handleSubmit(onSubmit) as any}
          theme={'dark'}
        />
      </div>
    </form>
  )
}

export default StepPersonalDate
