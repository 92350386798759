import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import './App.scss'
import './components/style.scss'
import Login from './pages/auth/login'
import Register from './pages/auth/register'
import './pages/auth/register/style.scss'
import Footer from './pages/footer'
import Header from './pages/header'
import DocumentsPage from './pages/static-pages/documents'
import Traffics from './pages/traffics-sms'
import './styles/colors.scss'
import './styles/text.scss'
import './pages/profile/style.scss'
import { decodeLink, requestLinkOfferStorage } from './request/hooks/api'

const App = () => {
  const [linkOffer, setLinkOffer] = React.useState<String | null>(null)

  React.useEffect(() => {
    requestLinkOfferStorage()
  })

  if (new URL(document.URL).searchParams.get('l')) {
    decodeLink({ link: new URL(document.URL).searchParams.get('l') }).then(
      data => setLinkOffer(data.data.link)
    )
  }

  return (
    <React.Fragment>
      <BrowserRouter>
        <Header city={''} />
        <Routes>
          <Route
            path="/*"
            element={
              <Traffics
                key={JSON.stringify(linkOffer)}
                link={linkOffer as any}
              />
            }
          />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register location="" />} />
          <Route path="/documents" element={<DocumentsPage />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </React.Fragment>
  )
}

export default App
